import { useState } from 'react';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import callDciApi from '../../utils/callDciApi';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';

type WorkItemOperationResult = {
    itemsSucceeded: number,
    itemsFailed: number,
    message: string
};

type StatusChangeWorkItemActionDialogProps = {
    title: string,
    workItemIds: number[],
    newStatusId: number,
    action?: string,
    isOpen: boolean,
    close: (refresh: boolean) => void
};

type ActionResponse = {
    result: 'SUCCESS' | 'FAILURE' | 'WARNINGS',
    messages: string[]
};

const StatusChangeWorkItemActionDialog = ({ title, workItemIds, newStatusId, action, isOpen, close }: StatusChangeWorkItemActionDialogProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ noteContent, setNoteContent ] = useState('');
    
    const getQuery = () => {
        if (action) {
            return `mutation{${action}(workItemIds:[${workItemIds.join()}]${noteContent === '' ? '' : `,noteContent:"""${noteContent}"""`}){result,messages}}`;
        }

        return `mutation{setWorkItemStatus(workItemIds:[${workItemIds.join()}],newStatusId:${newStatusId}${noteContent === '' ? '' : `,noteContent:"""${noteContent}"""`}){itemsFailed,itemsSucceeded,message}}`;
    };

    const onSave = async () => {
        // TODO: Convert these to Promise.all when have time to research
        const token = await getAccessTokenSilently();
        const query = getQuery();
        
        callDciApi(query, token)
        .then(body => {
            if (!body.errors) {
                if (action) {
                    const successBody = body.data[action] as ActionResponse;

                    if (successBody.messages.length > 0) {
                        alert(successBody.messages.join('\n'));
                    }

                    // Close the dialog - refresh if there were any successes
                    closeDialog(successBody.result === 'SUCCESS' || successBody.result === 'WARNINGS');
                } else {
                    const successBody = body.data.setWorkItemStatus as WorkItemOperationResult;
    
                    if (successBody.itemsFailed > 0) {
                        alert(successBody.message);
                    }
    
                    // Close the dialog - refresh if there were any successes
                    closeDialog(successBody.itemsSucceeded > 0);
                }
            }
        });
    }

    const closeDialog = (refresh: boolean) => {
        setNoteContent('');
        close(refresh);
    }

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Optionally enter an explanatory note
                </DialogContentText>
                
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Type your note here"
                    type="text"
                    fullWidth
                    multiline
                    rows={8}
                    variant={'outlined'}
                    value={noteContent}
                    onChange={(event) => setNoteContent(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog(false)}>Cancel</Button>
                <Button onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export { StatusChangeWorkItemActionDialog };