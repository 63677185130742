import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
    Avatar,
    Box,
    List, 
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    SxProps,
    Theme,
    useTheme
} from '@mui/material';
import {
    Business,
} from '@mui/icons-material';
import dciPaths from '../../utils/dciPaths';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display:'flex'
//     },
//     orgMenu: {
//         marginRight:20,
//         maxWidth: 250,
//         minWidth: 250,
//         '& .Mui-selected .MuiAvatar-root': {
//             backgroundColor: theme.palette.secondary.main
//         }
//     },
//     orgContent: {
//         flex:1,
//     }
// }));

const useStyles = () =>{
    const theme = useTheme();

    return {
        root: {
            display:'flex'
        } as SxProps<Theme>,
        orgMenu: {
            marginRight:20,
            maxWidth: 250,
            minWidth: 250,
            '& .Mui-selected .MuiAvatar-root': {
                backgroundColor: theme.palette.secondary.main
            }
        } as SxProps<Theme>,
        orgContent: {
            flex:1,
        } as SxProps<Theme>
    }
};

type CoreContainerProps = {
    children?: React.ReactNode
}

const CoreContainer = ({ children }: CoreContainerProps) => {
    const pathName = useLocation().pathname;
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <Box sx={classes.root}>
            <Paper sx={classes.orgMenu}>
                <List>
                    <ListItem selected={['/core','/core/tables'].indexOf(pathName) !== -1} button onClick={() => navigate(dciPaths.core.buildLink())}>
                        <ListItemAvatar>
                            <Avatar><Business /></Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Extract Tables" />
                    </ListItem>
                </List>
            </Paper>
            <Box sx={classes.orgContent}>
                {children}
            </Box>
        </Box>
    )
};

export { CoreContainer };