import { useAuth0 } from "@auth0/auth0-react";
import { LinearProgress, Paper, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useStore } from "zustand";
import { appGlobalStore } from "../../AppGlobalStore";
import { postDciApi } from "../../utils/callDciApi";
import { UserClient } from "../Api/CurrentUser";
import { LocalClient } from "../Api/LocalClient";
import { DciLogo } from "./DciLogo";
import { DciThemeContext } from "./DciTheme";

interface LoadSplashProps {
    progress: number,
    currentItem: string,
    currentItemStatus: LoadingState
}

const LoadSplash = ({ progress, currentItem, currentItemStatus }: LoadSplashProps) => {
    return (
        <Paper style={{ height:'100vh', display:'flex', justifyContent:'center', alignContent:'center', flexDirection:'column' }}>
            <div style={{ width:'300px', margin: '25px auto' }}>
                <DciLogo />
            </div>
            <div style={{
                width: '500px',
                margin: '0px auto'
            }}>
                <LinearProgress variant='determinate' value={progress} color={'secondary'} />
                <p>{ currentItem } ... { currentItemStatus }</p>
            </div>
        </Paper>
    )
}

type LoadingState = 'In Progress' | 'Error' | 'Success';

interface LoadingElement {
    description: string,
    work: () => Promise<void>
}

interface SplashScreenLoaderProps {
    children: JSX.Element
}

const SplashScreenLoader = ({ children }: SplashScreenLoaderProps) => {
    const setCurrentUser = useStore(appGlobalStore, s => s.setCurrentUser);
    const setDisplayConfig = useStore(appGlobalStore, s => s.setDisplayConfig);
    const setUserHasCorePermissions = useStore(appGlobalStore, s => s.setUserHasCorePermissions);

    const dciTheme = useContext(DciThemeContext);
    const theme = useTheme();
    const { getAccessTokenSilently } = useAuth0();
    const [ loadingState, setLoadingState ] = useState<LoadingState>('In Progress');
    const [ currentElement, setCurrentElement ] = useState(0);
    
    const getUserSettingsFromStorage = async () => {
        const colorMode = LocalClient.get('User.Settings.ColorMode');
        if ((colorMode === 'dark' || colorMode === 'light') && theme.palette.mode !== colorMode) {
            dciTheme.setColorMode(colorMode);
        }
    }

    const getUserSettingsFromServer = async () => {
        const user = await UserClient.getCurrentUser();
        setCurrentUser(user);

        if (theme.palette.mode !== user.userSettings.colorMode) {
            dciTheme.setColorMode(user.userSettings.colorMode);
            LocalClient.set('User.Settings.ColorMode', user.userSettings.colorMode);
        }
    }

    const getDisplayConfig = async () => {
        const token = await getAccessTokenSilently();
        const apiResponse = await postDciApi('{displayConfiguration{displayConfigId,config}}', token);
        const displayConfig = (apiResponse.data.displayConfiguration as any[]).map(m => ({
            displayConfigId: m.displayConfigId,
            config: JSON.parse(m.config)
        }));

        setDisplayConfig(displayConfig);
    }

    const getUserCorePermissions = async () => {
        const token = await getAccessTokenSilently();
        const apiResponse = await postDciApi('{userHasCorePermissions}', token);
        setUserHasCorePermissions(apiResponse.data.userHasCorePermissions);
    }

    const elementsToLoad: LoadingElement[] = [
        {
            description: 'Loading local settings',
            work: getUserSettingsFromStorage
        },
        {
            description: 'Loading local settings',
            work: getUserSettingsFromServer
        },
        {
            description: 'Loading display configuration',
            work: getDisplayConfig
        },
        {
            description: 'Loading user permissions',
            work: getUserCorePermissions
        }
    ];

    useEffect(() => {
        const doWork = async () => {
            try {
                await elementsToLoad[currentElement].work();
                if (currentElement < elementsToLoad.length - 1) {
                    setCurrentElement(currentElement + 1);
                } else {
                    setLoadingState('Success');
                }
            } catch (error) {
                console.error(`${elementsToLoad[currentElement].description} resulted in an error.`);
                setLoadingState('Error');
            }
        };

        doWork();
    }, [ currentElement ]);

    return loadingState === 'Success'
        ? children
        : <LoadSplash
            progress={currentElement / elementsToLoad.length * 100}
            currentItem={elementsToLoad[currentElement].description}
            currentItemStatus={loadingState} />
}

export { SplashScreenLoader }