import { parseISO } from "date-fns";
import { postDciApi } from "../../utils/callDciApi";
import authClient from "../Auth/auth-client";
import { ColorMode } from "../Layout/DciTheme";
import { LegacyDashboardSettings } from "../../AppGlobalStore";

type CurrentUserQueryResult = {
    firstName: string,
    surname: string,
    userSettings?: string | null,
    userId: number,
    organisation: Organisation,
    userRoleTeam: UserRoleTeam[]
};

type Organisation = {
    organisationName: string
};

type UserRoleTeam = {
    teamId: number,
    role: Role
};

type Role = {
    roleId: number,
    enabled: boolean,
    rolePermissions: RolePermission[]
};

type RolePermission = {
    permissionId: number
};

type UserPermission = {
    permissionId: number,
    teamIds: number[]
};

interface UserSettings {
    lastWhatsNewSeen: Date,
    colorMode: ColorMode,
    legacyDashboard: LegacyDashboardSettings
};

const defaultUserSettings = {
    lastWhatsNewSeen: parseISO('1970-01-01'),
    colorMode: 'light'
};

const UserClient = {
    getCurrentUser: async () => {
        const token = await authClient.getTokenSilently();
        const apiResponse = await postDciApi('{currentUser{userId,firstName,surname,userSettings,organisation{organisationName}userRoleTeam{teamId,role{roleId,enabled,rolePermissions{permissionId}}}}}', token);

        const result = apiResponse.data.currentUser as CurrentUserQueryResult;

        const parsedUserSettings = JSON.parse(result.userSettings ?? '{}');
        const parsedLegacyDashboardSettings = typeof parsedUserSettings.legacyDashboard === 'string'
            ? JSON.parse(parsedUserSettings.legacyDashboard)
            : {
                filterType: 'none',
                workQueueIds: [],
                teamIds: []
              }

        const mapped = {
            firstName: result.firstName,
            surname: result.surname,
            appUserId: result.userId,
            organisationName: result.organisation.organisationName,
            userSettings: {
                lastWhatsNewSeen: parseISO(parsedUserSettings.lastWhatsNewSeen ?? '1970-01-01'),
                colorMode: typeof parsedUserSettings.colorMode === 'string' && [ 'light', 'dark' ].includes(parsedUserSettings.colorMode.toLowerCase()) ? parsedUserSettings.colorMode.toLowerCase() : 'light',
                legacyDashboard: {
                    filterType: parsedLegacyDashboardSettings.filterType ?? 'none',
                    workQueueIds: parsedLegacyDashboardSettings.workQueueIds ?? [],
                    teamIds: parsedLegacyDashboardSettings.teamIds ?? [],
                }
            },
            permissions: result.userRoleTeam.reduce<UserPermission[]>((acc, m) => {
                if (m.role.enabled) {
                    m.role.rolePermissions.forEach(element => {
                        const existingPermission = acc.find(x => x.permissionId === element.permissionId);
                        if (typeof(existingPermission) === 'undefined') {
                            acc.push({ permissionId:element.permissionId, teamIds:[ m.teamId ] });
                        } else if (existingPermission.teamIds.indexOf(m.teamId) === -1) {
                            existingPermission.teamIds.push(m.teamId);
                        }
                    });
                }

                return acc;
            }, [])
        };

        return mapped;
    },

    saveCurrentUserSettings: async (settings: UserSettings) => {
        const token = await authClient.getTokenSilently();

        const query = `
            mutation {
                saveUserSettings(
                    settings:{
                        lastWhatsNewSeen:"${settings.lastWhatsNewSeen.toISOString()}",
                        colorMode:${settings.colorMode.toUpperCase()},
                        legacyDashboard:"${JSON.stringify(settings.legacyDashboard).replaceAll('"', '\\"')}"
                    }
                ) {
                    result
                }
            }
        `.replaceAll(' ', '').replaceAll('\n', '');

        postDciApi(query, token);
    }
};

export { UserClient }