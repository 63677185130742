import React, { useEffect, useMemo, useState } from 'react';

import {
    Box,
    Card,
    CardContent,
    Grid,
    MenuItem,
    Select,
    Stack,
    SxProps,
    Theme,
    Typography
} from '@mui/material';

import { ActiveWorkItemCountByPriorityCard } from '../Reporting/ActiveWorkItemCountByPriorityCard';
import { subDays } from 'date-fns';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import dciPaths from '../../utils/dciPaths';
import { ActiveWorkItemCountHeadlineCardDataProvider } from '../DataProviders/ActiveWorkItemCountHeadlineCardDataProvider';
import { HeadlineCard } from '../Reporting/HeadlineCard2';
import { OverdueWorkItemCountHeadlineCardDataProvider } from '../DataProviders/OverdueWorkItemCountHeadlineCardDataProvider';
import { ActiveWorkItemCountByPriorityDataProvider } from '../DataProviders/ActiveWorkItemCountByPriorityDataProvider';
import { ActiveWorkItemCountByRegulatoryImpactCard } from '../Reporting/ActiveWorkItemCountByRegulatoryImpactCard';
import { ActiveWorkItemCountByRegulatoryImpactDataProvider } from '../DataProviders/ActiveWorkItemCountByRegulatoryImpactDataProvider';
import { CoreKpisDataProvider } from '../DataProviders/CoreKpiDataProvider';
import { CoreKpisCard } from '../Reporting/CoreKpisCard';
import { TopRulesByVolumeDataProvider } from '../DataProviders/TopRulesByVolumeDataProvider';
import { DoughnutChart } from '../Reporting/DoughnutChart';
import { FilterAutocomplete } from '../Filtering/FilterAutocomplete';
import { useWorkQueues } from '../Api/WorkQueues';
import { useTeams } from '../Api/Teams';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import { ConfigurableChartCard } from './ConfigurableChartCard';
import { WorkItemCumulativeChartClient } from '../Api/WorkItemCumulativeChartClient';
import { DashboardWidgetProps } from './DashboardTypes';
import { ChartStoreContext, createChartStore, useChartState } from '../Reporting/Store/ChartStore';
import { AxisData, NewChart } from '../Reporting/NewChart';
import { PARAMETER_TYPE } from '../../GraphQLShared';

const classes= {
    root: {
        '& .MuiCard-root': {
            height:'100%'
        },
        '& .MuiCardContent-root': {
            height:'100%'
        }
    } as SxProps<Theme>,
    rootBox: {
        flexGrow: 1,
    } as SxProps<Theme>,
    chartCard: {
        height: 350
    } as SxProps<Theme>,
    headlineCard: {
        minHeight: '176px',
        '& .MuiCardContent-root': {
            display:'flex',
            flexDirection:'column',
        },
        '& .MuiCardContent-root .MuiTypography-root:first-of-type': {
            flex: '1 0 auto'
        },
        '& .MuiCardContent-root .MuiTypography-root:last-child': {
            marginBottom: 0
        }
    } as SxProps<Theme>
}

type ChartCardProps = {
    children?: React.ReactNode
}

const ChartCard = ({ children }: ChartCardProps) => {
    return (
        <Grid style={{ minWidth:0, minHeight:'350px' }} item xs={12} md={6}>
            <Card>
                <CardContent sx={classes.chartCard}>
                    {children}
                </CardContent>
            </Card>
        </Grid>
    );
};

const NewChartCard = ({ children }: ChartCardProps) => {
    return (
        <Grid style={{ minWidth:0, minHeight:'350px' }} item xs={12} md={6}>
            {children}
        </Grid>
    );
};

const onAxisClick = (event: MouseEvent, data: AxisData | null, navigate: NavigateFunction, workQueueIds: number[], teamIds: number[]) => {
    if (data === null) {
        return;
    }

    if (typeof data.axisValue !== 'string') {
        return;
    }

    const matches = data.axisValue.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (matches === null || matches?.[0] === null) {
        return;
    }

    let url = `${dciPaths.reporting.buildLink()}?event=Created&reportType=Item+List&on=${matches[3]}-${matches[2]}-${matches[1]}`;
    if (workQueueIds.length > 0) {
        url += `&filter.workQueueId=${workQueueIds.join()}`
    }

    if (teamIds.length > 0) {
        url += `&filter.workQueueId=${teamIds.join()}`
    }

    navigate(url);
}

const createStoreByGroupingColumn = (groupingColumn: string, start: Date, end: Date, workQueueIds: number[], teamIds: number[]) => createChartStore({ 
    graphQLQueryName: 'workItemsDailyChart' 
}, {
    fixedParameters: [
        {
            name: 'fixedFilter.reportingEventTypeId',
            type: PARAMETER_TYPE.NUMBER,
            value: 1 // Created
        },
        {
            name: 'fixedFilter.reportingEventDate',
            type: PARAMETER_TYPE.DATE,
            value: {
                onOrAfter: start,
                onOrBefore: end
            }
        },
        {
            name: 'groupingColumn',
            type: PARAMETER_TYPE.RAW_STRING,
            value: groupingColumn
        },
        {
            name: 'fixedFilter.workQueueId',
            type: PARAMETER_TYPE.NUMBER,
            value: workQueueIds
        },
        {
            name: 'fixedFilter.teamId',
            type: PARAMETER_TYPE.NUMBER,
            value: teamIds
        }
    ]
});

const ChartStoreUpdater = ({ groupingColumn, start, end, workQueueIds, teamIds }: DashboardWidgetProps) => {
    const setFixedParameters = useChartState(s => s.setFixedParameters);

    useEffect(() => setFixedParameters([
        {
            name: 'fixedFilter.reportingEventTypeId',
            type: PARAMETER_TYPE.NUMBER,
            value: 1 // Created
        },
        {
            name: 'fixedFilter.reportingEventDate',
            type: PARAMETER_TYPE.DATE,
            value: {
                onOrAfter: start,
                onOrBefore: end
            }
        },
        {
            name: 'groupingColumn',
            type: PARAMETER_TYPE.RAW_STRING,
            value: groupingColumn
        },
        {
            name: 'fixedFilter.workQueueId',
            type: PARAMETER_TYPE.NUMBER,
            value: workQueueIds
        },
        {
            name: 'fixedFilter.teamId',
            type: PARAMETER_TYPE.NUMBER,
            value: teamIds
        }
    ]), [ start, end, workQueueIds, teamIds ]);

    return <></>
}
    
const WorkItemsPerDayByRegulatoryImpact = ({ groupingColumn, start, end, workQueueIds, teamIds, style }: DashboardWidgetProps) => {
    const navigate = useNavigate();
    
    const [ store ] = useState(() => createStoreByGroupingColumn(groupingColumn, start, end, workQueueIds, teamIds));

    return <ChartStoreContext.Provider value={store}>
        <ChartStoreUpdater groupingColumn={groupingColumn} start={start} end={end} workQueueIds={workQueueIds} teamIds={teamIds} />
        <NewChart style={style} onAxisClick={(e, d) => onAxisClick(e, d, navigate, workQueueIds, teamIds)} />
    </ChartStoreContext.Provider>
};

const WorkItemsPerDayByPriority = ({ groupingColumn, start, end, workQueueIds, teamIds, style }: DashboardWidgetProps) => {
    const navigate = useNavigate();
    
    const [ store ] = useState(() => createStoreByGroupingColumn(groupingColumn, start, end, workQueueIds, teamIds));

    return <ChartStoreContext.Provider value={store}>
        <ChartStoreUpdater groupingColumn={groupingColumn} start={start} end={end} workQueueIds={workQueueIds} teamIds={teamIds} />
        <NewChart style={style} onAxisClick={(e, d) => onAxisClick(e, d, navigate, workQueueIds, teamIds)} />
    </ChartStoreContext.Provider>
};
        
const Dashboard = () => {
    const userSettings = useStore(appGlobalStore, s => s.currentUser.userSettings);
    const setUserSettings = useStore(appGlobalStore, s => s.updateUserSettings);

    const { data: workQueueData, isFetching: workQueuesFetching } = useWorkQueues();
    const { data: teamsData, isFetching: teamsFetching } = useTeams();

    const today = useMemo(() => new Date(), [ new Date().getDay() ]);
    const thirtyDaysAgo = useMemo(() => subDays(today, 29), [ today ]); // 29 because it's 30 days including today

    const getWorkQueuesTextValue = (value: number[]) => {
        if (value.length > 1) {
            return `${value.length} selected`;
        }

        const workQueue = workQueueData?.find(wq => wq.workQueueId === value[0]);
        return workQueue ? workQueue.name : `1 selected`;
    }
    
    const getTeamsTextValue = (value: number[]) => {
        if (value.length > 1) {
            return `${value.length} selected`;
        }

        const team = teamsData?.find(t => t.teamId === value[0]);
        return team ? team.name : `1 selected`;
    }

    const onSetFilter = (value: string) => {
        const newSettingsValue = {
            ...userSettings.legacyDashboard,
            filterType: value
        };

        if (value !== 'workQueue') {
            newSettingsValue.workQueueIds = [];
        }

        if (value !== 'team') {
            newSettingsValue.teamIds = [];
        }

        setUserSettings({ legacyDashboard: newSettingsValue });
    }

    return (
        <Box sx={classes.root}>
            <Stack direction='row' style={{ paddingBottom:'10px' }}>
                <Typography alignItems='flex-end' style={{ paddingRight:'4px' }}>Filter:</Typography>
                <Select
                    value={userSettings.legacyDashboard.filterType}
                    onChange={e => onSetFilter(e.target.value)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}
                    >
                    <MenuItem value='none'>None</MenuItem>
                    <MenuItem value='workQueue'>Work Queue</MenuItem>
                    <MenuItem value='team'>Team</MenuItem>
                </Select>
                { userSettings.legacyDashboard.filterType === 'workQueue' &&
                    <FilterAutocomplete
                        data={workQueueData?.map(wq => wq.workQueueId) ?? []}
                        getOptionLabel={o => workQueueData?.find(wq => wq.workQueueId === o)?.name ?? 'Loading'}
                        getTextValue={getWorkQueuesTextValue}
                        isLoading={workQueuesFetching}
                        isOptionEqualToValue={(o, v) => o === v}
                        selectedOptions={userSettings.legacyDashboard.workQueueIds}
                        setValue={v => setUserSettings({
                            legacyDashboard: {
                                ...userSettings.legacyDashboard,
                                workQueueIds: v,
                            }
                        })}
                    /> 
                }
                { userSettings.legacyDashboard.filterType === 'team' &&
                    <FilterAutocomplete
                        data={teamsData?.map(t => t.teamId) ?? []}
                        getOptionLabel={o => teamsData?.find(t => t.teamId === o)?.name ?? 'Loading'}
                        getTextValue={getTeamsTextValue}
                        isLoading={teamsFetching}
                        isOptionEqualToValue={(o, v) => o === v}
                        selectedOptions={userSettings.legacyDashboard.teamIds}
                        setValue={v => setUserSettings({
                            legacyDashboard: {
                                ...userSettings.legacyDashboard,
                                teamIds: v
                            }
                        })}
                    />
                }
            </Stack>
            <Grid sx={classes.rootBox} container spacing={4}>
                <Grid sx={classes.headlineCard} item xs={12} md={6} lg={3}>
                    <ActiveWorkItemCountHeadlineCardDataProvider workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds}>
                        <HeadlineCard />
                    </ActiveWorkItemCountHeadlineCardDataProvider>
                </Grid>
                <Grid sx={classes.headlineCard} item xs={12} md={6} lg={3}>
                    <OverdueWorkItemCountHeadlineCardDataProvider workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds}>
                        <HeadlineCard />
                    </OverdueWorkItemCountHeadlineCardDataProvider>
                </Grid>
                <Grid sx={classes.headlineCard} item xs={12} md={6} lg={3}>
                    <ActiveWorkItemCountByPriorityDataProvider workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds}>
                        <ActiveWorkItemCountByPriorityCard workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds} />
                    </ActiveWorkItemCountByPriorityDataProvider>
                </Grid>
                <Grid sx={classes.headlineCard} item xs={12} md={6} lg={3}>
                    <ActiveWorkItemCountByRegulatoryImpactDataProvider workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds}>
                        <ActiveWorkItemCountByRegulatoryImpactCard workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds} />
                    </ActiveWorkItemCountByRegulatoryImpactDataProvider>
                </Grid>
                <NewChartCard>
                    <WorkItemsPerDayByRegulatoryImpact groupingColumn='regulatoryImpactId' start={thirtyDaysAgo} end={today} workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds} />
                </NewChartCard>
                <NewChartCard>
                    <WorkItemsPerDayByPriority groupingColumn='workItemPriority' start={thirtyDaysAgo} end={today} workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds} />
                </NewChartCard>
                <ChartCard>
                    <ConfigurableChartCard workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds} disableOverdue={false} />
                </ChartCard>
                <Grid item xs={12} md={6}>
                    <CoreKpisDataProvider start={thirtyDaysAgo} end={today} workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds} >
                        <CoreKpisCard />
                    </CoreKpisDataProvider>
                </Grid>
                <ChartCard>
                    <TopRulesByVolumeDataProvider start={thirtyDaysAgo} end={today} limit={5} workQueueIds={userSettings.legacyDashboard.workQueueIds} teamIds={userSettings.legacyDashboard.teamIds}>
                        <DoughnutChart />
                    </TopRulesByVolumeDataProvider>
                </ChartCard>
            </Grid>
        </Box>
    );
}
        
export { Dashboard };