import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import OrgContainer from './OrgContainer';
import { permissions } from '../../utils/dciConstants';
import { isEqual, sortBy } from 'lodash';
import { 
    Box,
    Card,
    CardContent,
    Checkbox,
    Button,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import {
    Delete,
    Edit,
    Save
} from '@mui/icons-material';
import callDciApi, { CancellablePromise, callDciApiCancellable } from '../../utils/callDciApi';
import userHasPermission from '../../utils/userHasPermission';
import { AuditItemsByEntity } from '../AuditItemsByEntity';
import { SegmentMap } from '../Layout/DciBreadcrumb';
import { 
    Permission as ApiPermission,
    Role as ApiRole 
} from '../../utils/DciApiTypes';
import dciPaths from '../../utils/dciPaths';
import { ControlButton } from '../DciControls';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';

type Role = {
    description: string,
    enabled: boolean,
    permissions: number[]
};

type Permission = {
    permissionId: number,
    description: string,
    enabled: boolean
};

type RoleViewProps = {
    id: number,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
};

const RoleView: React.FC<RoleViewProps> = ({ id, setBreadcrumbs }) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [ allPermissions, setAllPermissions ] = useState<Permission[]>([]);
    const [ dummyRefresh, setDummyRefresh ] = useState(0);
    const [ role, setRole ] = useState<Role | null>(null);
    const [ editMode, setEditMode ] = useState(false);
    const [ newValue, setNewValue ] = useState<Role>({
        description: '',
        enabled: true,
        permissions: []
    });

    useEffect(() => {
        let cancelled = false;
        let cancellablePromise: CancellablePromise | null = null;

        const getPermissions = async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable('{allPermissions(order:{displayOrder:ASC}){permissionId,description,enabled}}', token)
            cancellablePromise.promise
            .then(body => {
                if (!body.errors) {
                    const newPermissions = body.data.allPermissions as ApiPermission[];
                    setAllPermissions(newPermissions.map(m => ({ permissionId: m.permissionId!, enabled: m.enabled!, description: m.description! })));
                }
            });
        }

        getPermissions();
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
    }, []);

    useEffect(() => {
        let cancelled = false;
        let cancellablePromise: CancellablePromise | null = null;
        const getData = async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable(`{roleById(id:${id}){roleId,description,enabled,rolePermissions{permissionId}}}`, token);
            cancellablePromise.promise
            .then(body => {
                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    const newRole = mappingFunction(body.data.roleById);
                    setRole(newRole)
                    setNewValue({ ...newRole, permissions: [ ...newRole.permissions ] });
                    setBreadcrumbs(oldValue => [ ...oldValue ].map((m, i) => i === oldValue.length - 1 ? { text: `${newRole.description}` } : m));
                }
            });
        };
        
        getData();
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
    }, [ id, dummyRefresh ]);

    const mappingFunction = (item: ApiRole): Role => {
        return {
            description: item.description!,
            enabled: item.enabled!,
            permissions: item.rolePermissions!.map(m => m.permissionId!)
        };
    };

    const cancelEdit = () => {
        setEditMode(false);
        setNewValue({
            description: role!.description,
            enabled: role!.enabled,
            permissions: [ ...role!.permissions ]
        });
    };

    const deleteRole = async () => {
        if (window.confirm(`Delete role named '${role!.description}'?`) === true) {
            const token = await getAccessTokenSilently();
            callDciApi(`mutation{deleteRole(roleId:${id})}`, token)
            .then(body => {
                if (!body.errors) {
                    navigate(dciPaths.roles.buildLink());
                } else {
                    alert(body.errors[0].message);
                }
            })
            .catch(error => {
                console.error(`[Role] editRole: ${error}`);
            });
        }
    }

    const saveChanges = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{editRole(roleId:${id},newName:"${newValue.description}",newEnabled:${newValue.enabled},newPermissionIds:[${newValue.permissions.join()}]){roleId}}`, token)
        .then(body => {
            if (!body.errors) {
                refresh();
                setEditMode(false);
            } else {
                alert(body.errors[0].message);
            }
        });
    }

    const refresh = () => {
        setDummyRefresh(dummyRefresh + 1);
    };

    const controls =
        <>
            { !editMode && userHasPermission(currentUser, permissions.DELETE_ROLE) && 
                <Button onClick={deleteRole} startIcon={<Delete />}>Delete</Button>
            }
            { !editMode && userHasPermission(currentUser, permissions.EDIT_ROLE) && 
                <Button onClick={() => setEditMode(true)} startIcon={<Edit />}>Edit</Button>
            }
        </>;

    const onPermissionCheck = (permissionId: number, checked: boolean) => {
        if (checked === true) {
            setNewValue({
                ...newValue,
                permissions: [
                    ...newValue.permissions,
                    permissionId
                ]
            })
        }
        else {
            var index = newValue.permissions.indexOf(permissionId);
            if (index !== -1) {
                setNewValue({
                    ...newValue,
                    permissions: [
                        ...newValue.permissions.slice(0, index),
                        ...newValue.permissions.slice(index + 1)
                    ]
                })
            }
        }
    };

    const selectAll = (checked: boolean) => {
        if (checked === true) {
            setNewValue({
                ...newValue,
                permissions: allPermissions.map(m => m.permissionId)
            })
        }
        else {
            setNewValue({
                ...newValue,
                permissions: []
            })
        }
    };

    const changesMade = (): boolean => {
        if (role === null) {
            return false;
        }

        if (newValue.description !== role.description) {
            return true;
        }

        if (newValue.enabled !== role.enabled) {
            return true;
        }

        if (!isEqual(sortBy(newValue.permissions), sortBy(role.permissions))) {
            return true;
        }

        return false;
    };

    return (
        <OrgContainer>
            <Card style={{ marginBottom:10 }}>
                <CardContent>
                    <Box style={{ float:'right' }}>{controls}</Box>
                    <Typography style={{ display:'inline' }} variant='h5' paragraph>Role: {newValue.description}</Typography>
                    { editMode &&
                        <TextField
                            variant='standard'
                            style={{ marginBottom:'12px' }}
                            margin="dense"
                            label='Description'
                            id='description'
                            type="text"
                            fullWidth
                            value={newValue.description}
                            onChange={(event) => setNewValue({ ...newValue, description: event.target.value })}
                        />
                    }
                    <Box>
                        <FormControlLabel
                            control={
                                <Switch 
                                    disabled={!editMode}
                                    checked={newValue.enabled} 
                                    onChange={event => setNewValue({ ...newValue, enabled:event.target.checked })} 
                                />
                            }
                            label='Enabled'
                        />
                    </Box>
                    { editMode &&
                        <>
                            <ControlButton onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                            <ControlButton onClick={cancelEdit} variant='contained'>Cancel</ControlButton>
                        </>
                    }
                </CardContent>
            </Card>
            <Card style={{ marginBottom:10 }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell padding='checkbox'><Checkbox color='secondary' disabled={!editMode} checked={newValue.permissions.length === allPermissions.length} onChange={event => selectAll(event.target.checked)} /></TableCell>
                            <TableCell>Permission</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allPermissions.map(m => m.enabled === true &&
                            <TableRow key={m.permissionId}>
                                <TableCell padding='checkbox'><Checkbox color='secondary' disabled={!editMode} checked={newValue.permissions.indexOf(m.permissionId) !== -1} onChange={event => onPermissionCheck(m.permissionId, event.target.checked)} /></TableCell>
                                <TableCell>{m.description}</TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </Card>
            <AuditItemsByEntity type='Role' entityKey={[{ fieldName:'RoleId', fieldValue:id }]} dummyRefresh={dummyRefresh} />
        </OrgContainer>
    )
};

export default RoleView;