import { FILTER_TYPE } from "../Filtering/FilterRepository";
import { TableColumnFilter } from "../Filtering/types";

const filterColumns: TableColumnFilter[] = [
    {
        name: 'filter.workItemPriority',
        displayName: 'Priority',
        type: FILTER_TYPE.PRIORITY
    },
    // TODO: Reintroduce for event based reporting screen
    // {
    //     name: 'filter.reportingEventUserId',
    //     displayName: 'Event User',
    //     type: FILTER_TYPE.USER
    // },
    {
        name: 'filter.processingUserId',
        displayName: 'Processing User',
        type: FILTER_TYPE.USER
    },
    {
        name: 'filter.regulatoryImpactId',
        displayName: 'Regulatory Impact',
        type: FILTER_TYPE.REGULATORY_IMPACT
    },
    {
        name: 'filter.reportMessage',
        displayName: 'Report Message',
        type: FILTER_TYPE.FREE_TEXT
    },
    {
        name: 'filter.reportTestId',
        displayName: 'Rule',
        type: FILTER_TYPE.RULE
    },
    {
        name: 'filter.workItemStatusId',
        displayName: 'Status',
        type: FILTER_TYPE.WORK_ITEM_STATUS
    },
    {
        name: 'filter.teamId',
        displayName: 'Team',
        type: FILTER_TYPE.TEAM
    },
    {
        name: 'filter.workQueueId',
        displayName: 'Work Queue',
        type: FILTER_TYPE.WORK_QUEUE
    },
    {
        name: 'filter.creationDatetime',
        displayName: 'Found',
        type: FILTER_TYPE.DATE
    },
    {
        name: 'filter.dueDate',
        displayName: 'Due',
        type: FILTER_TYPE.DATE
    },
];

export { filterColumns }