import { QuerySort } from "./types/QueryTypes";
import { Base64 } from 'js-base64';
import { QueryParameter } from "./GraphQLShared";

type BuildQueryParams = {
    rowsPerPage: number,
    page: number,
    uniqueSortColumn: string,
    paged?: boolean,
    queryParameters?: QueryParameter[],
    queryName: string, 
    queryColumns: string, 
    sort: QuerySort[],
    
    // querySort?: QuerySort[], 
    // // mappingFunction should be a generic type
    // mappingFunction?: (m: any) => TRow, 
    // columns: DciTableColumn[],
    // // TODO: Ideally the below will have the proper event type
    // onRowClick?: (row: TRow, state:any, path:string, event:any) => void,
    // title?: string,
    // AdditionalControls?: (props: AdditionalControlsProps) => JSX.Element, 
    // selectable?: boolean,
    // filterable?: boolean,
    // tableId?: string,
    // idFromValue: (value: TRow) => RowId,
    // refreshTable?: any,
    // hideToolbar?: boolean,
    // disableSelectByFilter?: boolean,
    // size?: 'small' | 'medium',
    // style?: React.CSSProperties
};

const buildSortProperty = (cols: QuerySort[], uniqueSortColumn: string): string => {
    const processed = [ ...cols ];
    if (processed.findIndex(c => c.column === uniqueSortColumn) === -1) {
        processed.push({ column: uniqueSortColumn });
    }

    const sortClauses = processed.map(c => {
        const split = c.column.split('.');
        const replaceToken = '<<REPLACE>>';
        const agg = split.reduce((prev, current, index, array) => {
            if (index === array.length - 1) {
                return prev.replace(replaceToken, `{${current}:${c.direction ?? 'ASC'}}`);
            } else {
                return prev.replace(replaceToken, `{${current}:${replaceToken}}`);
            }
        }, replaceToken);

        return agg;
    })

    if (sortClauses.length === 1) {
        return sortClauses[0];
    }

    return `[${sortClauses.join('')}]`;
}

const buildQueryUsingPrebuiltFilter = (
    rowsPerPage: number,
    page: number,
    uniqueSortColumn: string,
    paged: boolean = false,
    queryParameters: QueryParameter[] = [],
    sort: QuerySort[],
    queryName: string,
    queryColumns: string
) => {
    const pageFilterProps: string[] = [];
    if (paged) {
        pageFilterProps.push(`first:${rowsPerPage}`);

        if (page > 0) {
            pageFilterProps.push(`after:"${Base64.encode(JSON.stringify(page * rowsPerPage - 1))}"`);
        }
    }
    
    // if (sort) {
    //     const appliedSort = Array.isArray(sort) ? [ ...sort ] : [ sort ];
    //     if (typeof(uniqueSortColumn) === 'string' && appliedSort.findIndex(x => x.column === uniqueSortColumn) === -1) {
    //         appliedSort.push({ column:uniqueSortColumn, direction:'ASC' });
    //     }
        
    //     pageFilterProps.push(`order:{${ appliedSort.map(m => `${m.column}:${m.direction ?? 'ASC'}`).join() }}`);
    // }
    pageFilterProps.push(`order:${buildSortProperty(sort, uniqueSortColumn)}`);

    pageFilterProps.push(...queryParameters.map(qp => `${qp.name}:${qp.value}`));

    const query = paged
        ? `{${queryName}(${pageFilterProps.join(',')}){totalCount,nodes${queryColumns}}}`
        : `{${queryName}(${pageFilterProps.join(',')})${queryColumns}}`;

    return query;
}

const buildQuery = ({ rowsPerPage, page, uniqueSortColumn, paged, queryParameters = [], sort, queryName, queryColumns }: BuildQueryParams): string => {
    const filters: string[] = [];
    let aggregatedFilter = null;

    if (filters.length > 0) {
        aggregatedFilter = `{and:[${filters.map(m => `{${m}}`).join('')}]}`;
    }

    return buildQueryUsingPrebuiltFilter(
        rowsPerPage,
        page,
        uniqueSortColumn,
        paged,
        queryParameters,
        sort,
        queryName,
        queryColumns
    );
};

export type { BuildQueryParams }
export { buildQuery, buildQueryUsingPrebuiltFilter }