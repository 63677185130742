import dciPaths from '../../utils/dciPaths';
import { Navigate } from 'react-router-dom';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';

const CoreRoute = ({ children }: { children: JSX.Element }) => {
    const userHasCorePermissions = useStore(appGlobalStore, s => s.userHasCorePermissions);
    return userHasCorePermissions 
        ? children
        : <Navigate to={dciPaths.root.buildLink()} />
};

export { CoreRoute };