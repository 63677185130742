import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MobileStepper
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';

type WhatsNewDialogProps = {
    isOpen: boolean,
    close: () => void,
    items: React.ReactNode[]
}

const WhatsNewDialog = ({ isOpen, close, items }: WhatsNewDialogProps) => {
    const updateUserSettings = useStore(appGlobalStore, s => s.updateUserSettings);
    const [ currentItem, setCurrentItem ] = useState(0);

    const closeDialog = async () => {
        updateUserSettings({ lastWhatsNewSeen: new Date() });
        close();
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={isOpen}
        >
            <DialogTitle>What's New?</DialogTitle>
            <DialogContent style={{ height:'600px' }}>
                <div style={{ display:'flex', flexDirection:'column', height:'100%' }}>
                    <div style={{ flexGrow:1, overflow:'auto' }}>
                        { items[currentItem] }
                    </div>
                    { items.length > 1 &&
                        <div>
                            <MobileStepper
                                style={{ backgroundColor:'inherit' }}
                                variant={items.length <= 6 ? 'dots' : 'progress'}
                                steps={items.length}
                                activeStep={currentItem}
                                position='static'
                                nextButton={
                                    <Button size="small" onClick={() => setCurrentItem(currentItem + 1)} disabled={currentItem === items.length - 1}>
                                        <KeyboardArrowRight />
                                        Next
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={() => setCurrentItem(currentItem - 1)} disabled={currentItem === 0}>
                                        <KeyboardArrowLeft />
                                        Previous
                                    </Button>
                                }
                                />
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export { WhatsNewDialog }