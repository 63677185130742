
interface GraphQLParameterApplicator {
    createOrAppendParameterValue: (existingValue: any, newValue: any) => any
    finalise: (value: any) => string
}

type QueryParameter = {
    name: string,
    type: ParameterType,
    value: any
};

const PARAMETER_TYPE = {
    NUMBER: 'Number',
    TEXT: 'Text',
    DATE: 'Date',
    STRING_ARRAY: 'String Array',
    RAW_STRING: 'Raw String',
    ENUM: 'Enum',
    WHERE_BOOLEAN: 'Where Boolean',
    RAW_NUMBER: 'Raw Number',
    CHART_DATASET: 'Chart Dataset' // TODO: Make these strings and allow code to manage themselves
} as const;

type ObjectValues<T> = T[keyof T];

type ParameterType = ObjectValues<typeof PARAMETER_TYPE>;

const addMultipleParameters = (parameters: QueryParameter[], target: any, uniqueParameterNameTypes: { name: string, type: ParameterType }[]) => {
    parameters.forEach(p => {
        addToUniqueParameters(uniqueParameterNameTypes, p);
        const applicator = getParameterApplicator(p.type);
        if (applicator === null) {
            throw Error('Unable to apply value to the parameters.');
        }

        addParameterToObject(p.name, p.value, applicator, target);
    });
}

const addToUniqueParameters = (set: { name: string, type: ParameterType }[], parameter: QueryParameter) => {
    if (set.findIndex(u => u.name === parameter.name && u.type === parameter.type) === -1) {
        set.push({ name: parameter.name, type: parameter.type });
    }
}

const addParameterToObject = (parameterName: string, parameterValue: any, applicator: GraphQLParameterApplicator, target: any) => {
    const sepIndex = parameterName.indexOf('.');
    if (sepIndex === -1) {
        if (typeof target[parameterName] === 'undefined') {
            target[parameterName] = applicator.createOrAppendParameterValue(null, parameterValue);
        } else {
            target[parameterName] = applicator.createOrAppendParameterValue(target[parameterName], parameterValue);
        }

        return;
    }

    const subPropertyName = parameterName.substring(0, sepIndex);
    if (typeof target[subPropertyName] !== 'object') {
        target[subPropertyName] = {};
    }

    addParameterToObject(parameterName.substring(sepIndex + 1), parameterValue, applicator, target[subPropertyName]);
}

const finaliseParameters = (parameters: any, uniqueNameTypes: { name: string, type: ParameterType }[]) => {
    uniqueNameTypes.forEach(u => {
        const containingObject = navigateDotSeparatedObject(parameters, u.name);
        const leaf = u.name.substring(u.name.lastIndexOf('.') + 1);

        const applicator = getParameterApplicator(u.type);
        if (!applicator) {
            throw new Error('Need an applicator for this part.');
        }

        containingObject[leaf] = applicator.finalise(containingObject[leaf]);
    })

    return serializeTopLevelParametersObject(parameters);
}

const navigateDotSeparatedObject = (parameters: any, name: string) => {
    const lastIndex = name.lastIndexOf('.');
    if (lastIndex === -1) {
        return parameters;
    }

    let currentObject = parameters;
    const pathSegments = name.substring(0, lastIndex).split('.');
    pathSegments.forEach(s => currentObject = currentObject[s]);

    return currentObject;
}

const serializeTopLevelParametersObject = (value: any) => {
    return Object.keys(value).map(key => ({
        name: key,
        value: serializeParameterObjectValue(value[key])
    }) as QueryParameter)
}

const serializeParameterObjectValue = (value: any): string => {
    if (typeof value === 'string') {
        return value as string;
    }

    const values = Object.keys(value).map(key => `${key}:${serializeParameterObjectValue(value[key])}`);
    return `{${values.join()}}`;
}

const repository: { parameterType: ParameterType, applicator: GraphQLParameterApplicator }[] = [];

const getParameterApplicator = (parameterType: ParameterType) => {
    const registration = repository.find(a => a.parameterType === parameterType);
    if (!registration) {
        console.error(`No applicator for ${parameterType} has been registered.`);
        return null;
    }

    return registration.applicator;
}

const registerParameterApplicator = (parameterType: ParameterType, applicator: GraphQLParameterApplicator) => {
    const existing = repository.find(a => a.parameterType === parameterType);
    if (existing) {
        existing.applicator = applicator;
    } else {
        repository.push({ parameterType: parameterType, applicator: applicator });
    }
}

registerParameterApplicator(PARAMETER_TYPE.TEXT, {
    createOrAppendParameterValue: (existingValue: string[] | null, newValue: string) => {
        if (Array.isArray(existingValue)) {
            return [ ...existingValue, newValue ];
        }

        return [ newValue ];
    },

    finalise: (value: string[]) => {
        if (Array.isArray(value) && value.length > 0) {
            return `{contains:[${value.map(s => `"${s.replace('"', '""')}"`).join()}]}`;
        }

        // This shouldn't happen
        return '';
    }
});

registerParameterApplicator(PARAMETER_TYPE.NUMBER, {
    createOrAppendParameterValue: (existingValue: number[] | null, newValue: number[]) => {
        if (Array.isArray(existingValue)) {
            return [ ...existingValue, ...newValue ];
        }

        return [ newValue ];
    },

    finalise: (value: number[]) => {
        if (Array.isArray(value) && value.length > 0) {
            return `{in:[${value.join()}]}`;
        }

        // This shouldn't happen
        return '';
    }
});

interface DateParameterValueType {
    onOrAfter?: Date
    onOrBefore?: Date
    on?: Date[]
}

const datePropToString = (propertyName: string, value?: Date | null) => {
    if (typeof value === 'undefined' || value === null) {
        return '';
    }

    // TODO: Due to serialization in some places dates can become strings, we need to sort it
    return `${propertyName}:"${typeof value === 'string' ? value : value.toISOString()}"`;
}

const dataArrayPropToString = (propertyName: string, value?: Date[] | null) => {
    if (typeof value === 'undefined' || value === null) {
        return '';
    }

    // TODO: Due to serialization in some places dates can become strings, we need to sort it
    return `${propertyName}:[${value.map(d => `"${typeof d === 'string' ? d : d.toISOString()}"`).join()}]`;
}

registerParameterApplicator(PARAMETER_TYPE.DATE, {
    createOrAppendParameterValue: (existingValue: DateParameterValueType | null, newValue: DateParameterValueType): DateParameterValueType => {
        const aggregated: DateParameterValueType = {
            onOrAfter: newValue.onOrAfter ?? existingValue?.onOrAfter,
            onOrBefore: newValue.onOrBefore ?? existingValue?.onOrBefore,
            on: []
        }

        if (existingValue !== null && Array.isArray(existingValue.on)) {
            aggregated.on = existingValue.on;
        }

        if (Array.isArray(newValue.on)) {
            aggregated.on = [ ...aggregated.on!, ...newValue.on ];
        }

        return aggregated;
    },

    finalise: (value: DateParameterValueType) =>
        `{${datePropToString('onOrAfter', value.onOrAfter)} ${datePropToString('onOrBefore', value.onOrBefore)} ${dataArrayPropToString('on', value.on)}}`
});

registerParameterApplicator(PARAMETER_TYPE.STRING_ARRAY, {
    createOrAppendParameterValue: (existingValue: string[] | null, newValue: string[]) => {
        if (Array.isArray(existingValue)) {
            return [ ...existingValue, ...newValue ];
        }

        return newValue;
    },

    finalise: (value: string[]) => {
        if (Array.isArray(value) && value.length > 0) {
            return `[${value.map(s => `"${s.replace('"', '""')}"`).join()}]`;
        }

        // This shouldn't happen
        return '';
    }
});

registerParameterApplicator(PARAMETER_TYPE.RAW_STRING, {
    createOrAppendParameterValue: (existingValue: string | null, newValue: string | null) => {
        return newValue;
    },

    finalise: (value: string | null) => {
        return value ? `"${value.replace('"', '""')}"` : 'null';
    }
});

registerParameterApplicator(PARAMETER_TYPE.ENUM, {
    createOrAppendParameterValue: (existingValue: string | null, newValue: string | null) => {
        return newValue;
    },

    finalise: (value: string | null) => {
        return value ? value : 'null';
    }
});

registerParameterApplicator(PARAMETER_TYPE.WHERE_BOOLEAN, {
    createOrAppendParameterValue: (existingValue: boolean | null, newValue: boolean) => {
        return newValue;
    },

    finalise: (value: boolean) => {
        return `${value}`;
    }
});

registerParameterApplicator(PARAMETER_TYPE.RAW_NUMBER, {
    createOrAppendParameterValue: (existingValue: number | null, newValue: number) => {
        return newValue;
    },

    finalise: (value: number) => {
        return value.toString();
    }
});

export {
    addMultipleParameters,
    addParameterToObject,
    addToUniqueParameters,
    finaliseParameters,
    getParameterApplicator,
    PARAMETER_TYPE,
    registerParameterApplicator
}


export type { QueryParameter, GraphQLParameterApplicator, ParameterType }