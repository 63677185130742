import { useAuth0 } from "@auth0/auth0-react";
import { Archive, AssignmentInd, AssignmentLate, AssignmentReturn, AssignmentTurnedIn, Block, Check, FolderShared, Info, KeyboardArrowDown, MoveUp, SupervisorAccount } from "@mui/icons-material"
import { alpha, Button, Menu, MenuItem, MenuProps, styled } from "@mui/material"
import { useEffect, useState } from "react";
import callDciApi from "../../utils/callDciApi";
import { workItemStatus } from "../../utils/dciConstants";
import { AssignToQueueDialog } from "../AssignToQueueDialog";
import { OverrideRequestWorkItemActionDialog } from "./OverrideRequestWorkItemActionDialog";
import { StatusChangeWorkItemActionDialog } from "./StatusChangeWorkItemActionDialog";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 26,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

type WorkItemActionsMenuProps = {
    workItemIds: number[],
    refreshParent?: () => void
}

const WorkItemActionsMenu = ({ workItemIds, refreshParent }: WorkItemActionsMenuProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const [ actionsRetrievedAt, setActionsRetrievedAt ] = useState<number | null>();
    const [ actionsRetrievedForIds, setActionsRetrievedForIds ] = useState<string | null>();
    const [ operations, setOperations ] = useState<string[]>([]);
    const [ loading, setLoading ] = useState(false);

    const [ statusChangeDialogIsOpen, setStatusChangeDialogIsOpen ] = useState(false);
    const [ statusChangeDialogTitle, setStatusChangeDialogTitle ] = useState('');
    const [ statusChangeDialogNewStatus, setStatusChangeDialogNewStatus ] = useState(0);
    const [ statusChangeAction, setStatusChangeAction ] = useState<string>();

    const [ overrideRequestDialogIsOpen, setOverrideRequestDialogIsOpen ] = useState(false);

    const [ assignDialogIsOpen, setAssignDialogIsOpen ] = useState(false);

    const isOpen = Boolean(anchorEl);

    const openStatusChangeDialog = (newStatusId: number, title: string, action?: string) => {
        setStatusChangeDialogNewStatus(newStatusId);
        setStatusChangeDialogTitle(title);
        setStatusChangeDialogIsOpen(true);
        setStatusChangeAction(action);
    };

    const acceptWorkItems = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{acceptWorkItems(workItemIds:[${workItemIds.join()}])}`, token)
        .then(body => {
            if (!body.errors) {
                if (refreshParent) {
                    refreshParent();
                    //getAvailableActions(workItemIds.join());
                }
            } else {
                alert(body.errors[0].message);
            }
        });
    };
    
    const operationMenuItems = [
        {
            operationName: "AssignToWorkQueue",
            icon: <AssignmentInd />,
            caption: "Assign to Work Queue",
            handleClick: () => setAssignDialogIsOpen(true)
        },
        {
            operationName: "AcceptWorkItem",
            icon: <FolderShared />,
            caption: "Accept Work Item",
            handleClick: acceptWorkItems
        },
        {
            operationName: "MarkAsCorrectionApplied",
            icon: <Check />,
            caption: "Correction Applied",
            handleClick: () => openStatusChangeDialog(workItemStatus.CORRECTED, "Correction Applied")
        },
        {
            operationName: "MarkAsInformationRequired",
            icon: <Info />,
            caption: "Information Required",
            handleClick: () => openStatusChangeDialog(workItemStatus.AWAITING_INFORMATION, "Information Required")
        },
        {
            operationName: "ReferToManager",
            icon: <SupervisorAccount />,
            caption: "Refer to Manager",
            handleClick: () => openStatusChangeDialog(workItemStatus.REFERRED, "Refer to Manager")
        },
        {
            operationName: "CancelReferral",
            icon: <Archive />,
            caption: "Cancel Referral",
            handleClick: () => openStatusChangeDialog(workItemStatus.PROCESSING, "Cancel Referral")
        },
        {
            operationName: "ReturnToQueue",
            icon: <AssignmentReturn />,
            caption: "Return to Queue",
            handleClick: () => openStatusChangeDialog(workItemStatus.QUEUED, "Return to Queue")
        },
        {
            operationName: "RequestOverride",
            icon: <MoveUp />,
            caption: "Request Override",
            handleClick: () => setOverrideRequestDialogIsOpen(true)
        },
        {
            operationName: "CancelOverrideRequest",
            icon: <Archive />,
            caption: "Cancel Override Request",
            handleClick: () => openStatusChangeDialog(workItemStatus.PROCESSING, "Cancel Override Request")
        },
        {
            operationName: "ApproveOverrideRequest",
            icon: <AssignmentTurnedIn />,
            caption: "Approve Override Request",
            handleClick: () => openStatusChangeDialog(workItemStatus.OVERRIDE_APPROVED, "Approve Override Request")
        },
        {
            operationName: "ApproveOverrideRequestForSameDataOnly",
            icon: <AssignmentLate />,
            caption: "Approve Override Request for Same Data Only",
            handleClick: () => openStatusChangeDialog(workItemStatus.OVERRIDE_APPROVED_SAME_DATA_ONLY, "Approve Override Request for Same Data Only")
        },
        {
            operationName: "DenyOverrideRequest",
            icon: <AssignmentReturn />,
            caption: "Deny Override Request",
            handleClick: () => openStatusChangeDialog(workItemStatus.QUEUED, "Deny Override Request")
        },
        {
            operationName: "ReallocateWorkQueue",
            icon: <AssignmentReturn />,
            caption: "Re-allocate Work Queue",
            handleClick: () => openStatusChangeDialog(workItemStatus.RECEIVED, "Re-allocate Work Queue")
        },
        {
            operationName: "CancelOverride",
            icon: <Block />,
            caption: "Cancel Override",
            handleClick: () => openStatusChangeDialog(workItemStatus.QUEUED, "Cancel Override", 'cancelOverride')
        },
    ];

    const getAvailableActions = async (joinedWorkItemIds: string) => {
        if (joinedWorkItemIds === '') {
            setOperations([]);
            setActionsRetrievedAt(Date.now());
            setActionsRetrievedForIds(joinedWorkItemIds);
            return;
        }

        const token = await getAccessTokenSilently();
        const query = `{availableWorkItemActions(workItemIds:[${joinedWorkItemIds}]){operationName}}`;

        setLoading(true);
        callDciApi(query, token)
        .then(body => {
            setLoading(false);
            if (!body.errors) {
                const newOperations = body.data.availableWorkItemActions.map((m: any) => m.operationName) as string[];
                setOperations(newOperations);
                setActionsRetrievedAt(Date.now());
                setActionsRetrievedForIds(joinedWorkItemIds);
                if (newOperations.length < 2 && anchorEl !== null) {
                    setAnchorEl(null);
                }
            }
        });
    }

    useEffect(() => {
        getAvailableActions(workItemIds.join());
    }, [ workItemIds.join() ])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        const twoMinutes = 120000;
        const currentIds = workItemIds.join();
        const now = Date.now();
        if (!actionsRetrievedAt || now - actionsRetrievedAt > twoMinutes || actionsRetrievedForIds !== currentIds) {
            getAvailableActions(currentIds);
        }

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeStatusChangeDialog = (refresh: boolean) => {
        setStatusChangeDialogIsOpen(false);
        if (refresh && refreshParent) {
            refreshParent();
            //getAvailableActions(workItemIds.join());
        }
    };

    const closeOverrideRequestDialog = (refresh: boolean) => {
        setOverrideRequestDialogIsOpen(false);
        if (refresh && refreshParent) {
            refreshParent();
            //getAvailableActions(workItemIds.join());
        }
    };

    const closeAssignToQueueDialog = (refresh: boolean) => {
        setAssignDialogIsOpen(false);
        if (refresh && refreshParent) {
            refreshParent();
            //getAvailableActions(workItemIds.join());
        }
    };

    const singleOption = operations.length === 1 ? operationMenuItems.find(menuItem => menuItem.operationName === operations[0]) : undefined;

    return (
    <>
        { singleOption
            ? <Button
                id="single-workitem-action-button"
                style={{ margin: '0px 8px' }}
                variant='contained'
                disableElevation
                onClick={singleOption.handleClick}
                startIcon={singleOption.icon}
            >
                {singleOption.caption}
            </Button>
            : <>
                <Button
                    id="workitem-actions-menu-button"
                    style={{ margin: '0px 4px' }}
                    aria-controls={ isOpen ? 'workitem-actions-menu' : undefined }
                    aria-haspopup="true"
                    aria-expanded={ isOpen ? 'true' : undefined }
                    variant='contained'
                    disableElevation
                    disabled={workItemIds.length === 0 || operations.length === 0}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDown />}
                >
                    Actions
                </Button>
                <StyledMenu
                    id='workitem-actions-menu'
                    anchorEl={anchorEl}
                    open={isOpen}
                    onClose={handleClose}
                >
                    { loading 
                        ? <MenuItem disabled>
                            Loading...
                        </MenuItem>
                        : operations.length === 0
                            ? <MenuItem disabled>
                                No Actions Available
                            </MenuItem>
                            : operationMenuItems
                                .filter(menuItem => operations.findIndex(operation => operation === menuItem.operationName) !== -1)
                                .map(m => 
                                    <MenuItem key={m.operationName} disableRipple onClick={() => { setAnchorEl(null); m.handleClick(); }}>
                                        {m.icon}
                                        {m.caption}
                                    </MenuItem>)
                }
                </StyledMenu>
        </>}
        <StatusChangeWorkItemActionDialog 
            workItemIds={workItemIds}
            isOpen={statusChangeDialogIsOpen}
            close={closeStatusChangeDialog}
            title={statusChangeDialogTitle}
            newStatusId={statusChangeDialogNewStatus}
            action={statusChangeAction}
        />

        <OverrideRequestWorkItemActionDialog
            workItemIds={workItemIds}
            isOpen={overrideRequestDialogIsOpen}
            close={closeOverrideRequestDialog}
        />

        {/* 
            This is never likely to be available from a menu of multiple options, but we will leave
            it here anyway, as the resulting behaviour will be very difficult to debug
            */}
        <AssignToQueueDialog
            workItemIds={workItemIds}
            isOpen={assignDialogIsOpen}
            close={closeAssignToQueueDialog}
        />
    </>
    )
}

export { WorkItemActionsMenu }