import React, { useState, useEffect, useMemo } from 'react';
import OrgContainer from './OrgContainer';
import { SegmentMap } from '../Layout/DciBreadcrumb';
import callDciApi, { CancellablePromise, callDciApiCancellable, postDciApi } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';
import { WorkQueue as ApiWorkQueue } from '../../utils/DciApiTypes';
import { PageTitle, TextProperty, FieldCaption, ControlButton } from '../DciControls';
import { 
    Autocomplete,
    Box, 
    Button, 
    Card, 
    CardContent, 
    Chip,
    SxProps, 
    TextField, 
    Theme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import dciPaths from '../../utils/dciPaths';
import { useNavigate } from 'react-router-dom';
import { permissions } from '../../utils/dciConstants';
import userHasPermission from '../../utils/userHasPermission';
import { Block, Edit, Save } from '@mui/icons-material';
import DciLink from '../DciLink';
import { ReportTypes } from '../../utils/ReportTypes';
import { encodeURI } from 'js-base64';
import { SearchableRuleList } from '../Rules/SearchableRuleList';
import { DciDialog } from '../Dialog';
import { AuditItemsByEntity } from '../AuditItemsByEntity';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import { PARAMETER_TYPE, QueryParameter } from '../../GraphQLShared';

const classes = {
    disableButton: {
        color:'red',
        marginRight: '5px',
        '&:hover': {
            backgroundColor:'#ff000010'
        }
    } as SxProps<Theme>
}

type Team = {
    name: string,
    teamId: number
};

type WorkQueue = {
    description: string,
    enabled: boolean,
    name: string,
    teams: Team[]
};

type WorkQueueViewProps = {
    id: number,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
};

type ActionResponse = {
    result: 'SUCCESS' | 'FAILURE' | 'WARNINGS',
    messages: string[]
};

type DisableOrDeleteWorkQueueResult = {
    result: 'SUCCESS' | 'FAILURE' | 'WARNINGS',
    message: string,
    actionTaken: 'NONE' | 'DISABLED' | 'DELETED'
};

const WorkQueueView: React.FC<WorkQueueViewProps> = ({ id, setBreadcrumbs }) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [ workQueue, setWorkQueue ] = useState<WorkQueue | null>(null);
    const [ newItem, setNewItem ] = useState<any>({});
    const [ editMode, setEditMode ] = useState(false);

    const [ teamListIsOpen, setTeamListIsOpen ] = useState(false);
    const [ teamsLoading, setTeamsLoading ] = useState(true);
    const [ teams, setTeams ] = useState<Team[]>([])

    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_TEAM);
    const [ dummyRefresh, setDummyRefresh ] = useState(0);

    // Dialog
    const [ dialogIsOpen, setDialogIsOpen ] = useState(false);
    const [ dialogTitle, setDialogTitle ] = useState('');
    const [ dialogContent, setDialogContent ] = useState('');
    const handleDialogClose = () => { setDialogIsOpen(false) };

    useEffect(() => {
        let cancelled = false;
        let cancellablePromise: CancellablePromise | null = null;
        const getData = async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable(`{workQueueById(id:${id}){description,enabled,name,teamWorkQueue{team{name,teamId}}}}`, token);
            cancellablePromise.promise
            .then(body => {
                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    const newWorkQueue = mappingFunction(body.data.workQueueById);
                    setWorkQueue(newWorkQueue);
                    setBreadcrumbs(oldValue => [ ...oldValue ].map((m, i) => i === oldValue.length - 1 ? { text: newWorkQueue.name } : m));
                }
            });
        };
        
        getData();
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
    }, [ id, dummyRefresh ]);

    useEffect(() => {
        let cancelled = false;
        if (!teamsLoading) {
          return undefined;
        }
    
        let cancellablePromise: CancellablePromise | null = null;
        const fetchData = async () => {
            const token = await getAccessTokenSilently();

            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable('{allTeams(where:{enabled:{eq:true}}){nodes{name,teamId}}}', token);

            cancellablePromise.promise
            .then(body => {
                if (!body.errors) {
                    setTeams(body.data.allTeams.nodes);
                    setTeamsLoading(false);
                }
            });
        };
    
        fetchData();
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
        
    }, [ teamsLoading ]);

    const mappingFunction = (item: ApiWorkQueue): WorkQueue => ({
        description: item.description!,
        enabled: item.enabled!,
        name: item.name!,
        teams: item.teamWorkQueue!.map(m => ({ 
            name: m.team!.name, 
            teamId: m.team!.teamId! 
        }))
    });

    const activeWorkItemsForQueueUrl = useMemo(() => {
        var reportParameters = {
            title: `Active Work Items for Work Queue: ${workQueue?.name}`,
            parameters: [
                {
                    name: "fixedFilter.workItemStatusId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ 1, 2, 3, 4, 6, 8 ]
                },
                {
                    name: "fixedFilter.workQueueId",
                    type: PARAMETER_TYPE.NUMBER,
                    value: [ id ]
                }
            ] as QueryParameter[]
        };

        const base64 = encodeURI(JSON.stringify(reportParameters));
        return `/workitems?q=${base64}`;
    }, [ id, workQueue?.name ]);

    const beginEdit = () => {
        setNewItem({
            ...workQueue,
            teams: [ ...workQueue!.teams ]
        });

        setEditMode(true);
    };

    const disableOrDeleteWorkQueue = async () => {
        const token = await getAccessTokenSilently();
        callDciApi(`mutation{disableOrDeleteWorkQueue(workQueueId:${id}){result,message,actionTaken}}`, token)
        .then(body => {
            if (body.errors) {

            } else {
                const result = body.data.disableOrDeleteWorkQueue as DisableOrDeleteWorkQueueResult;

                if (result.result === 'WARNINGS' || result.result === 'FAILURE') {
                    enqueueSnackbar('Could not disable Work Queue', { variant: 'error' });
                    setDialogTitle('Could not disable Work Queue');
                    setDialogContent(result.message);
                    setDialogIsOpen(true);
                } else {
                    enqueueSnackbar(result.message, { variant: 'success' });
                    if (result.actionTaken === 'DELETED') {
                        navigate(dciPaths.workQueues.buildLink());
                    }
                }
            }
        })
    };

    const cancelEdit = () => {
        setEditMode(false);
    }

    const changesMade = () => {
        if (newItem.name !== workQueue!!.name) {
            return true;
        }

        if (newItem.description !== workQueue!!.description) {
            return true;
        }

        if (newItem.enabled !== workQueue!!.enabled) {
            return true;
        }

        if (newItem.teams.length !== workQueue!!.teams.length) {
            return true;
        }

        const newItemTeams = newItem.teams.map((m: Team) => m.teamId).sort();
        const currentTeams = workQueue!!.teams.map(m => m.teamId).sort();

        for (let index = 0; index < newItemTeams.length; index++) {
            if (newItemTeams[index] !== currentTeams[index]) {
                return true;
            }
        }

        return false;
    };

    const save = async () => {
        const token = await getAccessTokenSilently();
        postDciApi(`mutation{editWorkQueue(workQueueId:${id},name:"${newItem.name}",description:"${newItem.description}",teamIds:[${newItem.teams.map((m: Team) => m.teamId).join(',')}]){result,messages}}`, token)
        .then(body => {
            if (body.errors) {
                enqueueSnackbar('An unexpected error has occurred.', { variant: 'error' });
            } else {
                const result = body.data.editWorkQueue as ActionResponse;

                if (result.result === 'FAILURE') {
                    enqueueSnackbar(result.messages.join('<br />'), { variant: 'error' });
                } else if (result.result === 'WARNINGS') {
                    enqueueSnackbar(result.messages.join('<br />'), { variant: 'warning' });
                    setEditMode(false);
                    setDummyRefresh(old => old + 1);
                } else if (result.result === 'SUCCESS') {
                    enqueueSnackbar(result.messages.length > 0 ? result.messages.join('<br />') : 'Success', { variant: 'success' });
                    setEditMode(false);
                    setDummyRefresh(old => old + 1);
                }
            }
        });
    };

    return (
        <OrgContainer>
            <DciDialog content={dialogContent} handleClose={handleDialogClose} open={dialogIsOpen} title={dialogTitle} />
            <Card style={{ marginBottom:'20px' }}>
                <CardContent>
                { workQueue &&
                <>
                    { editMode ?
                        <TextField
                            variant='standard'
                            style={{ marginBottom:'12px' }}
                            margin="dense"
                            label='Name'
                            id='name'
                            type="text"
                            fullWidth
                            value={newItem.name}
                            onChange={(event: any) => setNewItem({ ...newItem, name: event.target.value })}
                        />
                      : <>
                            <Box style={{ float:'right' }}>
                                {workQueue.enabled && hasEditPermission && !editMode && <Button sx={classes.disableButton} onClick={disableOrDeleteWorkQueue} startIcon={<Block />}>Disable</Button>}
                                {workQueue.enabled && hasEditPermission && !editMode && <Button onClick={beginEdit} startIcon={<Edit />}>Edit</Button>}
                            </Box>
                            <Box component="span" style={{ marginRight:'10px' }}>
                                <PageTitle style={{ display:'inline' }} title={`Work Queue: ${workQueue.name}`} />
                            </Box>
                            <Box component="span">
                                <DciLink href={activeWorkItemsForQueueUrl}>View active Work Items for this Work Queue</DciLink>
                            </Box>
                        </>
                    }
                    { editMode ?
                        <TextField
                            variant='standard'
                            style={{ marginBottom:'12px' }}
                            margin="dense"
                            label='Name'
                            id='description'
                            type="text"
                            fullWidth
                            value={newItem.description}
                            onChange={(event: any) => setNewItem({ ...newItem, description: event.target.value })}
                        />
                      : <TextProperty caption='Description' value={workQueue.description} />
                    }
                    { !editMode && <TextProperty caption='Enabled' value={workQueue.enabled ? 'Yes' : 'No'} /> }
                    { editMode ?
                        <Autocomplete<Team, boolean>
                            style={{ marginBottom:10 }}
                            multiple
                            id='enabled'
                            size='small'

                            open={teamListIsOpen}
                            onOpen={() => setTeamListIsOpen(true)}
                            onClose={() => setTeamListIsOpen(false)}

                            value={newItem.teams}
                            isOptionEqualToValue={(option, value) => option.teamId === value.teamId}
                            onChange={(_event, value, _reason) => setNewItem({ ...newItem, teams: value })}

                            options={teams}
                            getOptionLabel={option => option.name}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" label="Teams" />
                            )}
                        /> :
                        <>
                            <FieldCaption caption='Teams' />
                            <Box>{ workQueue.teams.map(m => <Chip key={m.teamId} style={{ marginTop:'2px', marginRight:'2px', marginBottom:'17px' }} onClick={() => navigate(dciPaths.team.buildLink(m.teamId))} size='small' label={m.name} />)}</Box>
                        </>
                    }
                    { editMode &&
                        <>
                            <ControlButton disabled={!changesMade()} startIcon={<Save />} variant='contained' onClick={save}>Save</ControlButton>
                            <ControlButton onClick={cancelEdit} variant='contained'>Cancel</ControlButton>
                        </>
                    }
                </>
                }
                </CardContent>
            </Card>
            { workQueue && 
                <SearchableRuleList
                    style={{ height:'600px' }}
                    title={`Rules for Work Queue: ${workQueue.name}`}
                    queryFilter={[ { name:'where.defaultWorkQueueId.eq', type:PARAMETER_TYPE.RAW_NUMBER, value:id } ]}
                />
            }
            <AuditItemsByEntity style={{ marginTop:'10px' }} type='WorkQueue' entityKey={[{ fieldName:'WorkQueueId', fieldValue:id }]} dummyRefresh={dummyRefresh} />
        </OrgContainer>
    );
};

export default WorkQueueView;