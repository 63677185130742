import {
    Home,
    FormatListBulleted,
    Poll,
    Settings,
    History,
    ContactPage
 } from '@mui/icons-material';
import dciPaths from '../../utils/dciPaths';
import { permissions } from '../../utils/dciConstants';

type MenuItem = {
    icon?: JSX.Element,
    highlightPath?: string,
    label: string,
    linkTo?: string,
    preNavigateAction?: () => void
    permissions?: number[],
    subItems?: MenuItem[],
    selected?: (pathName: string) => boolean
}

const Menu: MenuItem[] = [
    {
        icon: <Home />,
        label: 'Dashboard',
        linkTo: '/dashboard',
        permissions: [ permissions.VIEW_DASHBOARD ]
    },
    {
        icon: <FormatListBulleted />,
        highlightPath: '/workitem',
        label: 'Work Items',
        subItems: [
            {
                label: 'Distribution',
                linkTo: '/workitems/distribution',
                permissions: [ permissions.VIEW_DISTRIBUTOR_WORK_QUEUE ],
                // TODO: Hopefully this step is no longer required
                //preNavigateAction: () => { distributionTableStore.getState().reset() }
            },
            {
                label: 'Administration',
                subItems: [
                    {
                        label: 'Queued',
                        linkTo: '/workitems/queued',
                        //preNavigateAction: () => { queuedTableStore.getState().reset() }
                    },
                    {
                        label: 'In Progress',
                        linkTo: '/workitems/inprogress',
                        //preNavigateAction: () => { inProgressTableStore.getState().reset() }
                    },
                    {
                        label: 'Awaiting Information',
                        linkTo: '/workitems/waitinginfo',
                        //preNavigateAction: () => { awaitingInformationTableStore.getState().reset() }
                    },
                ]
            },
            {
                label: 'Management',
                permissions: [permissions.VIEW_REQUEST_ACCEPTANCE_QUEUE, permissions.VIEW_LIST_OF_REFERRED_WORK_ITEMS],
                subItems: [
                    {
                        label: 'Override Request',
                        linkTo: '/workitems/overriderequest',
                        permissions: [ permissions.VIEW_REQUEST_ACCEPTANCE_QUEUE ],
                        //preNavigateAction: () => { overrideRequestTableStore.getState().reset() }
                    },
                    {
                        label: 'Referred',
                        linkTo: '/workitems/referred',
                        permissions: [ permissions.VIEW_LIST_OF_REFERRED_WORK_ITEMS ],
                        //preNavigateAction: () => { referredTableStore.getState().reset() }
                    },
                ]
            },
            {
                label: 'Completed',
                subItems: [
                    {
                        label: 'Corrected',
                        linkTo: '/workitems/corrected',
                        //preNavigateAction: () => { correctedTableStore.getState().reset() }
                    },
                    {
                        label: 'Override',
                        linkTo: '/workitems/override',
                        //preNavigateAction: () => { overrideTableStore.getState().reset() }
                    },
                ]
            }
        ]
    },
    {
        icon: <Poll />,
        label: 'Reporting',
        linkTo: '/reporting',
        permissions: [ permissions.VIEW_REPORTS ]
    },
    {
        icon: <History />,
        highlightPath: '/history',
        selected: (pathname: string) => pathname.startsWith('/history'),
        label: 'History',
        linkTo: dciPaths.history.buildLink()
    },
    {
        icon: <ContactPage />,
        highlightPath: dciPaths.userActivity.pattern,
        selected: (pathname: string) => pathname.startsWith(dciPaths.userActivity.pattern),
        label: 'User Activity',
        linkTo: dciPaths.userActivity.buildLink(),
        permissions: [ permissions.VIEW_USER_ACTIVITY ]
    },
    {
        icon: <Settings />,
        highlightPath: '/configuration',
        selected: (pathname: string) => pathname.startsWith('/configuration'),
        label: 'Configuration',
        linkTo: dciPaths.organisation.buildLink()
    }
];

export { Menu };
export type { MenuItem };