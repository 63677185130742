import { parseISO } from 'date-fns';
import { createStore } from 'zustand';
import { ColorMode } from './Components/Layout/DciTheme';
import { DisplayConfiguration } from './types/AppTypes';
import { UserClient } from './Components/Api/CurrentUser';

interface LegacyDashboardSettings {
    filterType: string,
    workQueueIds: number[],
    teamIds: number[]
}

type UserSettings = {
    lastWhatsNewSeen: Date,
    colorMode: ColorMode,
    legacyDashboard: LegacyDashboardSettings
};

type CurrentUser = {
    firstName?: string,
    surname?: string,
    appUserId?: number,
    organisationName?: string,
    userSettings: UserSettings,
    permissions: UserPermission[]
};

type UserPermission = {
    permissionId: number,
    teamIds: number[]
};

interface AppGlobalStore {
    currentUser: CurrentUser,
    displayConfig: DisplayConfiguration[],
    userHasCorePermissions: boolean,

    setCurrentUser: (currentUser: CurrentUser) => void,
    setDisplayConfig: (displayConfig: DisplayConfiguration[]) => void,
    setUserHasCorePermissions: (userHasCorePermissions: boolean) => void,

    updateUserSettings: (newSettings: Partial<UserSettings>) => void,
    updateUser: (modified: Partial<CurrentUser>) => void
}

const appGlobalStore = createStore<AppGlobalStore>((set, get) => ({
    currentUser: {
        userSettings: {
            lastWhatsNewSeen: parseISO('1970-01-01'),
            colorMode: 'light',
            legacyDashboard: {
                filterType: 'none',
                workQueueIds: [],
                teamIds: []
            }
        },
        permissions: []
    },
    displayConfig: [],
    userHasCorePermissions: false,

    setCurrentUser: (currentUser: CurrentUser) => set({ currentUser }),
    setDisplayConfig: (displayConfig: DisplayConfiguration[]) => set({ displayConfig }),
    setUserHasCorePermissions: (userHasCorePermissions: boolean) => set({ userHasCorePermissions }),

    updateUserSettings: (newSettings: Partial<UserSettings>) => 
        set(existing => {
            const newCurrentUserSettings = { 
                ...existing.currentUser.userSettings,
                ...newSettings
            };

            UserClient.saveCurrentUserSettings(newCurrentUserSettings);

            return {
                ...existing,
                currentUser: {
                    ...existing.currentUser,
                    userSettings: newCurrentUserSettings
                }
            };
        }),

    updateUser: (modified: Partial<CurrentUser>) => set(existing => ({
        currentUser: {
            ...existing.currentUser,
            ...modified
        }
    }))
}));

export { appGlobalStore };
export type { CurrentUser, LegacyDashboardSettings }