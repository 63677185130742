import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { callDciApiCancellable, CancellablePromise } from '../utils/callDciApi';

type SingleItemProps<T = any> = {
    queryName: string,
    queryParameters?: string,
    queryColumns: string,
    mappingFunction?: (input: any) => T,
    ItemComponent: React.ComponentType<ChildItemProps<T>>,
}

type ChildItemProps<T> = {
    item: T,
    refresh: () => void
};

function SingleItem<TItem>({ queryName, queryParameters, queryColumns, mappingFunction = input => input, ItemComponent }: SingleItemProps<TItem>) {
    const [ hasError, setHasError ] = useState(false);
    const [ loaded, setLoaded ] = useState(false);
    const [ item, setItem ] = useState<TItem | null>(null);
    const [ dummyRefresh, setDummyRefresh ] = useState(0);
    
    const { getAccessTokenSilently } = useAuth0();
    
    const refresh = () => setDummyRefresh(dummyRefresh + 1);
    
    const mapper = useCallback((input: any) => mappingFunction(input), []);

    useEffect(() => {
        let cancelled = false;
        const getApiQuery = () => {
            let url = `{${queryName}`;
            if (typeof(queryParameters) === 'string') {
                url += `(${queryParameters})`;
            }

            url += `${queryColumns}}`;
            return url;
        }

        let cancellablePromise: CancellablePromise | null = null;
        const fetchItem = async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable<TItem>(getApiQuery(), token);
            cancellablePromise.promise
            .then(body => {
                setLoaded(true);
                if (body.errors) {
                    setHasError(true);
                    setItem(null);
                }
                else {
                    setHasError(false);
                    setItem(body.data[queryName] !== null && mapper ? mapper(body.data[queryName]) : body.data[queryName]);
                }
            });
        };

        fetchItem();
        return () =>{
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
    }, [ queryName, mapper, getAccessTokenSilently, dummyRefresh ])

    if (loaded !== true) {
        return (<Typography>Loading...</Typography>);
    }
    else if (hasError === true) {
        return (<Typography>An error occurred.</Typography>);
    }
    else if (item == null) {
        return (<Typography>Record does not exist or you do not have permission to view it.</Typography>);
    }

    return <ItemComponent item={item} refresh={refresh} />
};

export { SingleItem }
export type { ChildItemProps }