import { WNActiveItemsChart } from './Items/WNActiveItemsChart';
import { WNCreateUser } from './Items/WNCreateUser';
import { WNDarkMode } from './Items/WNDarkMode';
import { WNDisableWorkQueue } from './Items/WNDisableWorkQueue';
import { WNFixesJune23 } from './Items/WNFixesJune23';
import { WNImprovedFilters } from './Items/WNImprovedFilters';
import { WNMultipleFilters } from './Items/WNMultipleFilters';
import { WNNewFiltersJune23 } from './Items/WNNewFiltersJune23';
import { WNShareableUrls } from './Items/WNShareableUrls';
import { WNUserActivity } from './Items/WNUserActivity';
import { WNViewItemsForTeam } from './Items/WNViewItemsForTeam';
import { WNWorkQueueView } from './Items/WNWorkQueueView';

type WhatsNewDatum = {
    item: React.ReactNode,
    date: Date,
    index?: number
}

const WhatsNewData: WhatsNewDatum[] = [
    {
        item: <WNDisableWorkQueue />,
        date: new Date(Date.parse('2022-10-31')),
        index: 1
    },
    {
        item: <WNWorkQueueView />,
        date: new Date(Date.parse('2022-10-31')),
        index: 2
    },
    {
        item: <WNCreateUser />,
        date: new Date(Date.parse('2022-11-06'))
    },
    {
        item: <WNViewItemsForTeam />,
        date: new Date(Date.parse('2022-11-08'))
    },
    {
        item: <WNImprovedFilters />,
        date: new Date(Date.parse('2023-03-08')),
        index: 1
    },
    {
        item: <WNMultipleFilters />,
        date: new Date(Date.parse('2023-03-08')),
        index: 2
    },
    {
        item: <WNDarkMode />,
        date: new Date(Date.parse('2023-03-08')),
        index: 3
    },
    {
        item: <WNNewFiltersJune23 />,
        date: new Date(Date.parse('2023-06-15')),
        index: 1
    },
    {
        item: <WNShareableUrls />,
        date: new Date(Date.parse('2023-06-15')),
        index: 2
    },
    {
        item: <WNFixesJune23 />,
        date: new Date(Date.parse('2023-06-15')),
        index: 3
    },
    {
        item: <WNActiveItemsChart />,
        date: new Date(Date.parse('2023-11-21'))
    },
    {
        item: <WNUserActivity />,
        date: new Date(Date.parse('2024-04-22'))
    }
];

const whatsNewDataSortFunction = (a: WhatsNewDatum, b: WhatsNewDatum) => {
    const timeDiff = a.date.getTime() - b.date.getTime();
    if (timeDiff !== 0) {
        return timeDiff;
    }

    return (a.index ?? 0) - (b.index ?? 0);
}

export { WhatsNewData, whatsNewDataSortFunction }