import { Box, CircularProgress, MenuItem, Select, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react";
import { ChartData } from "../../types";
import { DciChart } from "../Reporting/DciChartNonContext";
import { CancellablePromise } from "../../utils/callDciApi";
import { useAuth0 } from "@auth0/auth0-react";
import { addDays, format, parse } from "date-fns";
import { ChartStoreContext, createChartStore } from "../Reporting/Store/ChartStore";
import { NewChart } from "../Reporting/NewChart";
import { PARAMETER_TYPE, QueryParameter } from "../../GraphQLShared";
import { workItemReportingEventType } from "../../utils/dciConstants";

interface ConfigurableChartProps {
    metric: string,
    grouping: string,
    start: Date,
    end: Date,
    workQueueIds: number[],
    teamIds: number[],

    dataProvider: (metric: string, grouping: string, start: Date, end: Date, workQueueIds: number[], teamIds: number[], token: string) => CancellablePromise
}

const ConfigurableChart = ({ metric, grouping, start, end, workQueueIds, teamIds, dataProvider }: ConfigurableChartProps) => {
    // TODO: Have error state for retry
    const [ chartData, setChartData ] = useState<ChartData | null>(null);
    const [ loading, setLoading ] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        let cancellablePromise: CancellablePromise | null = null;
        const fetchData = async () => {
            setChartData(null);
            try {
                const token = await getAccessTokenSilently();
                setLoading(true);
                cancellablePromise = dataProvider(metric, grouping, start, end, workQueueIds, teamIds, token);
                cancellablePromise.promise.then(body => {
                    setLoading(false);
                    if (body.errors) {
                        console.error(body.errors[0].message);
                    } else {
                        setChartData(body.data.cumulativeWorkItemQuery);
                    };
                });
            } catch {
                // TODO: Toast?
                setLoading(false);
                console.error('CCC: Loading chart data failed.');
            }
        }

        fetchData();
        return () => cancellablePromise?.abortController.abort();

    }, [ metric, grouping, start, end, workQueueIds, teamIds, dataProvider ])

    return <Box style={{ height:'0px', minHeight:'350px', flexGrow:1, alignItems:'center', justifyContent:'center', flexDirection:'row', display:'flex'  }}>
        { loading ? <CircularProgress /> :
            chartData === null ? <Typography>No data to display</Typography> :
                <DciChart
                    chartData={chartData}
                    chartOptions={{
                        type:'line'
                    }}
                />
        }
    </Box>
}

const createParameters = (metric: string, grouping: string, numDays: number, workQueueIds: number[], teamIds: number[]) => {
    const dataset = {} as ChartDataset;

    if (metric === 'Added') {
        dataset.metricType = 'EVENT_COUNT';
        dataset.eventTypes = [ workItemReportingEventType.CREATED ];
    } else if (metric === 'Resolved') {
        dataset.metricType = 'EVENT_COUNT';
        dataset.eventTypes = [
            workItemReportingEventType.OVERRIDE_APPROVED,
            workItemReportingEventType.OVERRIDE_APPROVED_SAME_DATA_ONLY,
            workItemReportingEventType.CORRECTED,
            workItemReportingEventType.CORRECTED_AT_SOURCE
        ];
    } else if (metric === 'Active') {
        dataset.metricType = 'ACTIVE';
    } else {
        dataset.metricType = 'OVERDUE';
    }

    if (grouping !== 'None') {
        dataset.groupingColumn = grouping;
    }

    const now = new Date();
    const end = parse(format(now, 'yyyy-MM-dd'), 'yyyy-MM-dd', now);
    const start = addDays(end, -numDays + 1); // Inclusive of today

    const params = [] as QueryParameter[];
    params.push({
        name: 'datasets',
        type: PARAMETER_TYPE.CHART_DATASET,
        value: dataset
    });

    params.push({
        name: 'fixedFilter.reportingEventDate',
        type: PARAMETER_TYPE.DATE,
        value: { 
            onOrAfter: start,
            onOrBefore: end
        }
    });

    if (Array.isArray(workQueueIds) && workQueueIds.length > 0) {
        params.push({
            name: 'fixedFilter.workQueueId',
            type: PARAMETER_TYPE.NUMBER,
            value: workQueueIds
        });
    }

    if (Array.isArray(teamIds) && teamIds.length > 0) {
        params.push({
            name: 'fixedFilter.teamId',
            type: PARAMETER_TYPE.NUMBER,
            value: teamIds
        });
    }

    return params;
}

const createStoreByGroupingColumn = (metric: string, grouping: string, numDays: number, workQueueIds: number[], teamIds: number[]) => createChartStore({ 
    graphQLQueryName: 'timeSeriesWorkItemReport' 
}, {
    fixedParameters: createParameters(metric, grouping, numDays, workQueueIds, teamIds)
});

interface ConfigurableChartCardProps {
    workQueueIds: number[],
    teamIds: number[],
    disableOverdue: boolean
}

type MetricType = 'ACTIVE' | 'OVERDUE' | 'EVENT_COUNT';

interface ChartDataset {
    metricType: MetricType
    groupingColumn: string | null
    eventTypes: number[]
}

const ConfigurableChartCard = ({ workQueueIds, teamIds, disableOverdue = false }: ConfigurableChartCardProps) => {
    const [ store ] = useState(() => createStoreByGroupingColumn('Active', 'None', 30, workQueueIds, teamIds));

    const [ metric, setMetric ] = useState<string>('Active');
    const [ grouping, setGrouping ] = useState<string>('None');
    const [ numDays, setNumDays ] = useState(30);

    useEffect(() => {
        const state = store.getState();
        state.setFixedParameters(createParameters(metric, grouping, numDays, workQueueIds, teamIds));
    }, [ metric, grouping, numDays, workQueueIds, teamIds ])

    const metricOnChange = (value: string) => {
        setMetric(value);
        //updateStore();
    }

    const groupingOnChange = (value: string) => {
        setGrouping(value);
        //updateStore();
    }

    const numDaysOnChange = (value: number) => {
        setNumDays(value);
        //updateStore();
    }

    const updateStore = () => {
        const state = store.getState();
        state.setFixedParameters(createParameters(metric, grouping, numDays, workQueueIds, teamIds));
    };

    return (
        <Stack>
            <Stack direction='row' justifyContent='center'>
                <Select
                    value={metric}
                    onChange={e => metricOnChange(e.target.value)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value='Active'>Active</MenuItem>
                    { !disableOverdue ? <MenuItem value='Overdue'>Overdue</MenuItem> : null }
                    <MenuItem value='Added'>Created</MenuItem>
                    <MenuItem value='Resolved'>Resolved</MenuItem>
                </Select>

                <Select
                    value={grouping}
                    onChange={e => groupingOnChange(e.target.value)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value='None'>No Grouping</MenuItem>
                    <MenuItem value='regulatoryImpactId'>Regulatory Impact</MenuItem>
                    <MenuItem value='workItemPriority'>Priority</MenuItem>
                    <MenuItem value='workQueueId'>Work Queue</MenuItem>
                </Select>

                <Select
                    value={numDays}
                    onChange={e => numDaysOnChange(e.target.value as number)}
                    sx={{
                        width:'175px',
                        marginRight: '5px',
                        '& > .MuiSelect-outlined': { 
                            padding:'2px 10px',
                            fontSize:'14px'
                        }
                    }}>
                    <MenuItem value={5}>5 Days</MenuItem>
                    <MenuItem value={10}>10 Days</MenuItem>
                    <MenuItem value={15}>15 Days</MenuItem>
                    <MenuItem value={20}>20 Days</MenuItem>
                    <MenuItem value={25}>25 Days</MenuItem>
                    <MenuItem value={30}>30 Days</MenuItem>
                </Select>
            </Stack>
            {/* <ConfigurableChart metric={metric} grouping={grouping} start={start} end={end} workQueueIds={workQueueIds} teamIds={teamIds} dataProvider={dataProvider} /> */}

            <ChartStoreContext.Provider value={store}>
                {/* <ChartStoreUpdater groupingColumn={groupingColumn} start={start} end={end} workQueueIds={workQueueIds} teamIds={teamIds} /> */}
                <NewChart style={{ height:'350px' }} />
            </ChartStoreContext.Provider>
        </Stack>
    )
}

export { ConfigurableChartCard }